<template>
    <b-container v-if="category">
        <b-row>
            <b-col>
                <h3 class="mt-3">{{ category.id ? '修改商品分類' : '建立商品分類' }}</h3>
            </b-col>
            <b-col v-if="category.id">
                <b-button class="mt-3 float-right" variant="danger" @click="onDelete">刪除商品分類</b-button>
            </b-col>
        </b-row>

        <b-form class="mt-3" @submit.stop.prevent="onSubmit">
            <h5>內容</h5>
            <b-form-group label="名稱">
                <b-form-input v-model="category.name" required></b-form-input>
            </b-form-group>
            <b-form-group label="短稱 (打印名稱)">
                <b-form-input v-model="category.shortName"></b-form-input>
            </b-form-group>

            <h5>商品</h5>
            <b-form-group label="可選商品">
                <n-multiselect label="name" :options="products" :reset-after="true" @select="addProduct"></n-multiselect>
            </b-form-group>
            <b-form-group v-if="category.products.length > 0">
                <n-draggable class="list-group" :list="category.products">
                    <b-list-group-item v-for="(product, index) in category.products" :key="index">
                        <b-row>
                            <b-col class="pt-2">{{ product.name }}</b-col>
                            <b-col cols="4">
                                <b-button variant="danger" block @click="removeProduct(index)">刪除</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </n-draggable>
            </b-form-group>

            <b-row>
                <b-col>
                    <b-form-group>
                        <b-button block @click="$router.go(-1)">返回</b-button>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <b-button type="submit" variant="primary" block>儲存</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { Category } from '@/models'

export default {
    data() {
        return {
            action: this.$route.params.action,
            category: null,
        }
    },

    async created() {
        this.category = this.action == 'create' ? Category() : await this.$store.dispatch('getDocument', { col: 'categories', id: this.$route.params.id })
        if (!this.category) return this.$router.push('/categories')
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'products', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            products: state => state.products,
        })
    },

    methods: {
        addProduct(obj) {
            if (!this.category.products.find((product) => product.id == obj.id)) return this.category.products.push(Object.assign({}, obj))
        },

        removeProduct(index) {
            return this.category.products.splice(index, 1)
        },

        async onDelete() {
            const res = await this.$store.dispatch('deleteDocument', { col: 'categories', id: this.category.id })
            this.$root.$bvToast.toast(`刪除${res ? '成功' :'失敗'}`, { title: res ? '成功' :'失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/categories')
        },

        async onSubmit() {
            this.category.merchantId = localStorage.merchantId

            const res = await this.$store.dispatch(this.action == 'create' ? 'createDocument' : 'updateDocument', { col: 'categories', doc: this.category })
            this.$root.$bvToast.toast(`${this.action == 'create' ? '新增' : '更新'}${res ? '成功' :'失敗'}`, { title: res ? '成功' :'失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/categories')
        }
    }
}
</script>